<template>
  <en-table-layout :tableData="pageData.data" :loading="loading">
    <template slot="toolbar">
      <el-form-item label="提交时间" class="col-auto">
        <el-date-picker style="width: 220px" v-model="advancedForm.order_time_range" type="daterange" align="center"
          size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
          end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item class="col-auto">
        <el-input style="width: 280px" v-model.trim="params.member_mobile" placeholder="请输入手机号搜索" clearable>
        </el-input>
      </el-form-item>

      <div class="col-auto" style="margin-right: 10px">
        <el-button @click="advancedSearchEvent();" size="small" type="primary">搜索</el-button>
      </div>
    </template>
    <template slot="table-columns">
      <el-table-column label="提交人手机号" prop="member_mobile" width="200" />
      <el-table-column label="提交时间" width="220">
        <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
      </el-table-column>
      <el-table-column label="反馈内容" prop="content" show-overflow-tooltip />
    </template>
    <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
      :current-page="pageData.page_no" :page-size="pageData.page_size" :page-sizes="MixinPageSizes"
      :layout="MixinTableLayout" background :total="pageData.data_total" />
  </en-table-layout>
</template>

<script>
import { problemFeedbackList } from "@/api/shop";
import EnTableLayout from "../../../ui-components/TableLayout/src/main";
export default {
  name: "problemFeedback",
  components: {
    EnTableLayout
  },
  data () {
    return {
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        keywords: ''
      },
      /** 高级搜索数据 */
      advancedForm: {
        order_time_range: [],
      },
      /** 列表分页数据 */
      pageData: {},
    };
  },
  mounted () {
    this.GET_OrderList();
  },
  methods: {
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_no = 1;
      this.params.page_size = size;
      this.GET_OrderList(this.activeName);
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_OrderList(this.activeName);
    },

    /** 高级搜索事件触发 */
    advancedSearchEvent () {
      this.params = {
        ...this.params,
        ...this.advancedForm
      };
      delete this.params.start_create_time;
      delete this.params.end_create_time;
      if (
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length
      ) {
        if (typeof this.advancedForm.order_time_range[0] === "string") {
          this.params.start_create_time =
            new Date(this.advancedForm.order_time_range[0]).getTime() / 1000;
          this.params.end_create_time =
            new Date(this.advancedForm.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start_create_time =
            this.advancedForm.order_time_range[0].getTime() / 1000;
          this.params.end_create_time =
            this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
        }
      }
      delete this.params.order_time_range;
      this.params.page_no = 1;
      this.GET_OrderList();
    },

    GET_OrderList () {
      this.loading = true;
      // 企业平台商品，站点
      problemFeedbackList(this.params).then(res => {
        this.loading = false
        this.pageData = res;
      });
    },
  }
};

</script>

<style lang="scss" scoped>
@media screen and (max-width: 1545px) {
  .restyle1545 {
    margin-top: 12px;
  }
}

.restyle1545 {
  width: 410px;
}

/deep/ {
  .el-tabs__header {
    margin-bottom: 0;
  }

  .goods-cover-wrapper .cell {
    padding-left: 0 !important;
  }
}

.conceal {
  white-space: nowrap; //不换行
  overflow: hidden; //超出则隐藏
}

/deep/.import-order-dialog {
  .el-dialog {
    min-width: 1024px;
    margin-top: 10vh !important;
  }

  .el-dialog__body {
    max-height: 65vh;
    overflow-y: scroll;
  }
}

/deep/.el-loading-spinner {
  .el-icon-loading,
  .el-loading-text {
    color: white;
    font-size: 16px;
  }
}

.remark-box {
  max-width: 150px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
